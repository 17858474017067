import React, { useState, useEffect } from 'react';
import Loading from './Loading';

import EditIcon from '@mui/icons-material/Edit';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Input,
} from '@mui/material';

import { useMediaQuery } from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getSuppliersOrderPayments,
  editSupplierOrderPayment,
  addOrderPayment,
  getOrderPayments,
  addSupplierOrderPayment,
} from '../APIS';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

const useFakeMutation = () => {
  return React.useCallback(
    (payment) =>
      new Promise((resolve, reject) => {
        resolve({ ...payment });
      }),
    []
  );
};

const SuppliersOrderPaymentsDialog = (props) => {
  const [suppliersOrderPayments, setSuppliersOrderPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      type: 'number',
    },

    {
      field: 'amount',
      headerName: 'المبلغ',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
    },
    {
      field: 'dateadded',
      headerName: 'تاريخ الدفعة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'text',
      valueGetter: (value, row) => {
        return formatDate(new Date(row.dateadded));
      },
    },
    {
      field: 'notes',
      headerName: 'الملاحظات',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'text',
      editable: true,
    },
  ];

  // Function to format date to yyyy/mm/dd
  function formatDate(date) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 since getMonth() returns 0-11
    let day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  const handleAddButton = async () => {
    const object = {
      amount: amount,
      notes: notes,
      orderId: props.orderId,
    };

    if (amount) {
      setErrorMessage('');
      try {
        setLoading(true);
        const response = await addSupplierOrderPayment(object);

        if (response.status !== 'success') {
          toast.error('لم يتم اضافة المبلغ');
        } else {
          toast.success('تمت اضافة المبلغ');
          const orderPayments = await getSuppliersOrderPayments(props.orderId);
          setSuppliersOrderPayments(orderPayments.data);
          setAmount(0);
          setNotes('');
          setLoading(false);
        }
      } catch (error) {
        throw error;
      }
    } else {
      // setErrorMessage(' رجاءً ، أدخل المبلغ');
      toast.error('رجاءً ، أدخل المبلغ');
    }
  };

  useEffect(() => {
    const fetchSuppliersOrderPayments = async () => {
      try {
        setLoading(true);
        const response = await getSuppliersOrderPayments(props.orderId);
        console.log('AAAAAAAAAAA  ', response);
        setSuppliersOrderPayments(response.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchSuppliersOrderPayments();
    console.log('props: ', props);
  }, []);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const data = {
        notes: newRow.notes,
        id: newRow.id,
      };
      const result = await editSupplierOrderPayment(data);
      if (result.status !== 'success') {
        toast.error('لم تتم عملية التعديل بنجاح حاوب مرة اخرى', 'top-left');
      } else if (result.status == 'success') {
        toast.success('تم التعديل بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  // const handleImageClick = (imageUrl) => {
  //   setSelectedImage(imageUrl);
  //   setOpenImageModal(true);
  // };

  return (
    <>
      {' '}
      {loading ? <Loading /> : null}
      <Dialog
        PaperProps={{ sx: { minWidth: '900px', textAlign: 'right' } }}
        open={props.openPaymentsDialog}
        onClose={() => props.setOpenPaymentsDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box sx={{ padding: 5, paddingTop: 1 }}>
          <Box sx={{ width: '98%', direction: 'rtl' }}>
            <DataGrid
              onRowClick={(row) => {
                // setSelectedRow(row.row);
              }}
              sx={{
                height: 400,
                padding: '5px',
                '& .MuiDataGrid-cell': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              getRowHeight={() => 'auto'}
              rows={suppliersOrderPayments}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableExporting
              // disableColumnSorting
              disableRowSelectionOnClick
              disableColumnMenu
              pageSizeOptions={[10, 25, 50, 100]}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: {
                    disableToolbarButton: true,
                  },
                },
              }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          </Box>

          <Box
            sx={{
              direction: 'rtl',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: 1,
              padding: 1,
              margin: 1,
              backgroundColor: '#f9f9f9',
            }}
          >
            <Box
              sx={{
                borderRadius: 1,
                padding: 1,
                margin: 1,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography>المبلغ</Typography>
              <Input
                sx={{ margin: 1 }}
                autoFocus
                type='number'
                placeholder='المبلغ'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: 1,
                padding: 1,
                margin: 1,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography>الملاحظات</Typography>
              <Input
                sx={{ margin: 1, width: '90%', direction: 'rtl' }}
                multiline={true}
                autoFocus
                placeholder='الملاحظات'
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Box>
          </Box>

          <Box sx={{ margin: '10px' }}>
            <Button
              color='primary'
              onClick={() => {
                handleAddButton();
              }}
            >
              اضافة
            </Button>
          </Box>

          <Box>
            <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default SuppliersOrderPaymentsDialog;
