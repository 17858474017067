import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { setPassword } from '../APIS';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const notifySuccess = (message) => {
    toast.success(message, 'top-right');
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
    });
  };

  const handleResetPassword = async () => {
    setLoading(true);
    if (newPassword === confirmNewPassword) {
      const response = await setPassword(token, newPassword);
      if (response.status === 'success') {
        setSuccess(true);
        navigate('/home');
      } else {
        setError(false);
      }
      setMessage(response.msg);
    } else {
      notifyError('الرجاء التأكد من التطابق');
      setError(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (success) {
      notifySuccess(message);
      setSuccess(false);
    }

    if (error) {
      notifyError(message);
      setError(false);
    }
  }, [success, error]);

  return (
    <>
      {loading ? <Loading /> : null}
      <div style={styles.container}>
        <div style={styles.header}>
          <h3>تغيير كلمة المرور</h3>
        </div>
        <div>
          <h3>كلمة المرور الجديدة</h3>
          <Input.Password
            style={styles.inputStyle}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder='كلمة المرور'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <h3>تأكيد كلمة المرور الجديدة</h3>
          <Input.Password
            style={styles.inputStyle}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            placeholder='كلمة المرور'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />

          <Button
            type='primary'
            style={styles.buttonStyle}
            onClick={handleResetPassword}
          >
            تغيير
          </Button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '20px',
    width: '400px',
  },
  inputStyle: {
    margin: '5px',
  },

  buttonStyle: {
    backgroundColor: '#fcc318',
    color: 'black',
    margin: '30px',
  },
};

export default ResetPassword;
