import React, { useEffect, useState } from 'react';

import Cart_Table_NonEditable from './Cart_Table_NonEditable';
import { toast } from 'react-toastify';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import Loading from './Loading';
import '../App.css';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';

import {
  getAllOrdersDetails,
  getCities,
  getCart,
  deleteOrder,
  editOrder,
  getOrderPayments,
  getOrderDetails,
} from '../APIS';
import PaymentsTable from './PaymentsTable';
import Typography from 'antd/es/typography/Typography';

const useFakeMutation = () => {
  return React.useCallback(
    (order) =>
      new Promise((resolve, reject) => {
        resolve({ ...order });
      }),
    []
  );
};

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [cart, setCart] = useState([]);
  const [openCartDialog, setOpenCartDialog] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(0);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const mutateRow = useFakeMutation();

  const sources = ['السوق الالكتروني', 'النظام'];

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'orderNumber',
      headerName: 'رقم الطلب',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'text',
    },

    {
      field: 'userId',
      headerName: 'رقم الزبون',
      align: 'center',
      flex: 1,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'customerName',
      headerName: 'اسم الزبون',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: true,
      type: 'text',
    },
    {
      field: 'city',
      headerName: 'المدينة',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: cities,
      align: 'center',
      editable: true,
    },
    {
      field: 'address',
      headerName: 'العنوان',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'mobile',
      headerName: 'الهاتف',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'coupon',
      headerName: 'الكوبون',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'text',
    },
    {
      field: 'couponType',
      headerName: 'نوع الكوبون',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'text',
    },
    {
      field: 'couponValue',
      headerName: 'قيمة الكوبون',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'number',
    },
    {
      field: 'date_added',
      headerName: 'تاريخ الاضافة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'Date',
      valueGetter: (value, row) => {
        const date = new Date(row.date_added); // Access the date_added field from the row object
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
      },
    },

    {
      field: 'discount',
      headerName: 'الخصم',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      field: 'shipping',
      headerName: 'التوصيل',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },

    {
      field: 'source',
      headerName: 'المصدر',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueOptions: sources,
      minWidth: 100,
      editable: true,
      type: 'singleSelect',
    },
    {
      field: 'subtotalAmount',
      headerName: 'المجموع',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'couponDiscount',
      headerName: 'خصم الكوبون',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'number',
    },
    {
      field: 'totalAmount',
      headerName: 'المجموع الكلي',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'number',
    },

    {
      field: 'paidAmount',
      headerName: 'المبلغ المدفوع',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'number',
    },
    {
      field: 'remainingAmount',
      headerName: 'المبلغ المتبقي',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      type: 'number',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const {
          id,
          row: { orderNumber },
        } = params;
        return [
          <GridActionsCellItem
            icon={<ProductionQuantityLimitsIcon sx={{ color: 'black' }} />}
            onClick={() => {
              handleClickOnCart(id);
              setSelectedOrderNumber(orderNumber);
            }}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<AttachMoneyIcon sx={{ color: 'black' }} />}
            onClick={() => {
              setOpenPaymentDialog(true);
              setSelectedOrderNumber(orderNumber);
              console.log('the order number is : ', orderNumber);
            }}
          />,
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label='file'
            onClick={() => {
              handlePrint(orderNumber);
            }}
          />,
        ];
      },
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editOrder(newRow);

      if (result.status !== 'success') {
        toast.error('لم يتم تعديل الطلب بنجاح', 'top-left');
      } else if (result.status == 'success') {
        toast.success('تم تعديل الطلب بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        const orders = await getAllOrdersDetails();
        setOrders(orders);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleClickOnCart = (id) => {
    // console.log(orders);
    orders.forEach((order, index) => {
      if (order.id == id) {
        const updatedCart = order.cart.map((item) => ({
          ...item,
          total: item.quantity * item.price, // Assuming each item has `quantity` and `price` fields
        }));
        setCart(updatedCart);
      }
    });
    setOpenCartDialog(true);
  };

  const fetchCart = async (id) => {
    let sub_total = 0;
    try {
      setLoading(true);

      const response = await getCart(id);
      {
      }
      if (response.status == 'success') setCart([...response.data]);

      setLoading(false);

      {
        console.log(response.data);
        response.data.map(
          (item, index) => (sub_total = sub_total + item.total)
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const handleConfirmDelete = async (id) => {
    setOpenDeleteConfirmation(false);
    setLoading(true);
    const response = await deleteOrder(id);
    const ordersData = await getAllOrdersDetails();

    if (response.status !== 'success') {
      toast.error(response.msg);
    } else if (response.msg == 'success') {
      toast.success(response.msg);
    }
    setOrders(ordersData);
    setLoading(false);
  };

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // Function to format date to yyyy/mm/dd
  function formatDate(date) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 since getMonth() returns 0-11
    let day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  // Function to format date to yyyy/mm/dd
  function formatDateToYMD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 since getMonth() returns 0-11
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  const handlePrint = async (orderNumber) => {
    try {
      setLoading(true);
      const order = await getOrderDetails(orderNumber);
      const payments = await getOrderPayments(orderNumber);
      setLoading(false);

      console.log('the order is :', order);

      let cartTable = order.cart.map((item, index) => {
        return `
          <tr>
            <td>${index + 1}</td>
            <td>${item.productItemNumber}</td>
            <td>${item.name}</td>
            <td style="color:${item.quantity == 0 ? 'red' : 'black'}">${
          item.quantity == 0 ? 'مرتجع' : item.quantity
        }</td>
            <td>${item.price}</td>
            <td>${item.price * item.quantity}</td>
          </tr>`;
      });

      cartTable = cartTable.join('\n');

      let paymentsTable = payments.data.map((item, index) => {
        return `
          <tr>
            <td>${index + 1}</td>
            <td>${item.amount}</td>
            <td>${formatDateToYMD(item.dateadded)}</td>
            <td>${item.notes}</td>  

          </tr>`;
      });

      paymentsTable = paymentsTable.join('\n');

      const printWindow = window.open('', '', 'width=1000,height=1200');
      printWindow.document.write(`
       
    <!DOCTYPE html>
    <html lang="ar">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=0.5">
        <title>فاتورة</title>
        <style>


        *{
          font-family: "Times New Roman", Times, serif;
        }
            body {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                direction: rtl;
                text-align: right;
                margin: 40px;
                background-color: #f9f9f9;
                color: #333;
            }
            .invoice-container {
                max-width: 700px;
                margin: auto;
                background-color: #fff;
                padding: 20px;
                border-radius: 8px;
            }
            .invoice-header, .invoice-footer {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width:80%;
                margin-left :auto;
                margin-right:auto;
            }
            .invoice-header div, .invoice-footer div {
                margin-bottom: 10px;
                width: 48%;
            }
            .invoice-title {
                text-align: center;
                margin-bottom: 20px;
            }
            .invoice-title h1 {
                margin: 0;
                font-size: 1em;
            }
            .invoice-title p {
                margin: 5px 0;
                font-size: 0.8em;
            }
            table {
                width: 80%;
                border-collapse: collapse;
                margin-bottom: 20px;
                margin-left :auto;
                margin-right:auto;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 3px;
            }
            th {
                background-color: #f2f2f2;
                text-align: center;
            }
            td {
                text-align: center;
            }
            .total {
                background-color: #f2f2f2;
                font-weight: bold;
            }
            .invoice-footer div {
                font-size: 0.8em;
               
            }
        </style>
    </head>
    <body>
    
        <div class="invoice-container">        
        <div class="invoice-title">
                <h1>  طلبية # ${order.orderNumber}</h1>
               
                <p><strong>الحمارشة للأدوات المنزلية </strong></p>
                 <p>تاريخ الطلبية: ${formatDateToYMD(order.date_added)}</p>
            </div>
            
            <div class="invoice-header">
            <div><strong>الاسم: </strong>${order.customerName}</div>
            <div><strong>الهاتف: </strong>${order.mobile}</div>
            <div><strong>المدينة: </strong>${order.city}</div>
            <div><strong>العنوان: </strong> ${order.address}</div>
        </div>
    
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>رقم المنتج</th>
                        <th>الاسم</th>
                        <th>الكمية</th>
                        <th>السعر</th>
                        <th>المجموع</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Rows of products will go here -->
                  
                      ${cartTable}
                        
                    <!-- Repeat above <tr> block for each product -->
                </tbody>
            </table>

            <h3 style="text-align: center;
            margin-bottom: 20px;">الدفعات</h3>


            <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>المبلغ</th>
                    <th>تاريخ الاضافة</th>
                    <th>الملاحظات</th>
                </tr>
            </thead>
            <tbody>
                <!-- Rows of products will go here -->
              
                  ${paymentsTable}
                    
                <!-- Repeat above <tr> block for each product -->
            </tbody>
        </table>

    
            <div class="invoice-footer">
                <div><strong>المجموع:</strong> ${order.subtotalAmount}</div>
                <div><strong>الكوبون:</strong> ${order.coupon}</div>
                <div><strong>خصم الكوبون: </strong>${order.couponDiscount}</div>
                <div><strong>الخصم:</strong> ${order.discount}</div>
               

                <div><strong> سعر التوصيل:</strong>${order.shipping}</div>
                
    
                <div class="total"><strong>المجموع الكلي:</strong> ${
                  order.totalAmount
                }</div>

                <div><strong>المبلغ المدفوع:</strong> ${order.paidAmount}</div>
                <div><strong>المبلغ المتبقي:</strong> ${
                  order.remainingAmount
                }</div>
            </div>
        </div>
    
    </body>
    </html>
    `);

      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchAllOrders = async () => {
      setLoading(true);
      const response = await getAllOrdersDetails();
      setOrders(response);
      console.log(response);
      setLoading(false);
    };

    const fetchCities = async () => {
      try {
        const cities = await getCities();
        console.log(cities.data);
        // Extracting names from the response and updating state
        setCities(cities.data.map((item) => item.name));
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchAllOrders();
    fetchCities();
  }, []);

  useEffect(() => {
    const fetchAllOrders = async () => {
      setLoading(true);
      const response = await getAllOrdersDetails();
      setOrders(response);
      console.log(response);
      setLoading(false);
    };

    fetchAllOrders();
  }, [openCartDialog, openPaymentDialog]);

  return (
    <>
      {loading && <Loading />}

      <Box sx={{ padding: 5 }}>
        <Box sx={{ width: '98%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              // setSelectedRow(row.row);
            }}
            sx={{
              height: '700px',
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={orders}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            // disableColumnSorting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>

      {/*Dialog Component*/}

      {openCartDialog && (
        <Dialog
          classes={{ paper: 'customDialog' }}
          PaperProps={{
            style: {
              maxWidth: '1500px',
              height: 'auto',
            },
          }}
          open={openCartDialog}
          onClose={() => setOpenCartDialog(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ textAlign: 'center', position: 'relative' }}
          >
            <Typography>{'سلة الشراء'}</Typography>
            <Button
              onClick={() => setOpenCartDialog(false)}
              sx={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                width: '30px',
                height: '30px',
                borderRadius: '100%',
              }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <Cart_Table_NonEditable
              cart={cart}
              fetchCart={fetchCart}
              orderNumber={selectedOrderNumber}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}

      {/*Dialog Component*/}

      {openPaymentDialog && (
        <Dialog
          classes={{ paper: 'customDialog' }}
          PaperProps={{
            style: {
              height: '700px',
            },
          }}
          open={openCartDialog}
          onClose={() => setOpenCartDialog(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ textAlign: 'center', position: 'relative' }}
          >
            {'سلة الشراء'}
          </DialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <Cart_Table_NonEditable
              cart={cart}
              fetchCart={fetchCart}
              orderNumber={selectedOrderNumber}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}

      {/*Dialog Component*/}

      {openPaymentDialog && (
        <Dialog
          classes={{ paper: 'customDialog' }}
          PaperProps={{
            sx: {
              height: '700px',
              width: '900px',
              maxWidth: '900px',
            },
          }}
          open={openPaymentDialog}
          onClose={() => setOpenPaymentDialog(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
            <Typography>{'مدفوعات الطلبية'}</Typography>
            <Button
              onClick={() => setOpenPaymentDialog(false)}
              sx={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                width: '30px',
                height: '30px',
                borderRadius: '100%',
              }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>

          <DialogContent sx={{ width: '100%' }}>
            <PaymentsTable orderNumber={selectedOrderNumber} />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}

      {/*Dialog Component*/}
      <Dialog
        sx={{ textAlign: 'right' }}
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'حذف الطلب'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            هل أنت متأكد من عملية الحذف ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirmation(false)}
            color='primary'
          >
            الغاء
          </Button>
          <Button
            onClick={() => handleConfirmDelete(selectedOrderId)}
            color='primary'
            autoFocus
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Orders;
