import React, { useState, useEffect } from 'react';
import Products from './Products';
import CartTable from './CartTable';
import {
  Box,
  Input,
  Button,
  Typography,
  TextField,
  Checkbox,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import Loading from './Loading';
import AddIcon from '@mui/icons-material/Add';
import Customers from './Customers';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import { useMediaQuery } from '@mui/material';

import { toast } from 'react-toastify';

import {
  addToCartByBarcode,
  getCart,
  addToCartById,
  checkCoupon,
  createOrder,
  getPendingCarts,
  getCityPrice,
} from '../APIS';
import { Check, CheckBox, CopyAll } from '@mui/icons-material';

const AddOrders = () => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [randomID, setRandomID] = useState(null);
  const [barCode, setBarCode] = useState('');
  const [priceType, setPriceType] = useState('0');
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [pindingCarts, setPindingCarts] = useState([]);
  const [selectedCartById, setSelectedCartById] = useState();
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openChooseCustomer, setOpenChooseCustomer] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [order, setOrder] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);

  // *********************************//
  const [subTotal, setSubTotal] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(0);
  const [discountCoupon, setDiscountCoupon] = useState(0);
  const [total, setTotal] = useState(0);
  const [couponvaluePercentage, setCouponvaluePercentage] = useState(0);
  const [couponType, setCouponType] = useState('');

  const [paidValue, setPaidValue] = useState(0);

  const isMobile = useMediaQuery('(max-width:600px)');

  const generateRandomNumber = () => {
    const newRandomNumber = Math.floor(Math.random() * 9000000) + 1000000;
    setRandomID(newRandomNumber);
    localStorage.setItem('randomID', newRandomNumber);

    fetchCart(newRandomNumber);
  };

  const newCart = () => {
    generateRandomNumber();
    setBarCode('');
    setSubTotal(0);
    setCoupon('');
    setDiscount(0);
    setDiscountCoupon(0);
    setTotal(0);
    setShippingPrice(0);
  };

  const handleAddToCartById = async (id) => {
    try {
      setLoading(true);
      const response = await addToCartById({
        cartId: localStorage.getItem('randomID'),
        productId: id,
        priceType: priceType,
      });

      fetchCart(localStorage.getItem('randomID'));

      if (response.status == 'error') {
        toast.error(response.msg, 'top-left');
      }
      console.log(response);

      setLoading(false);
    } catch (error) {
      throw error;
    }
  };
  const handleSubmitOrderWithCustomer = async () => {
    const object = {
      userId: order.id,
      city: order.city,
      address: order.address,
      mobile: order.mobile,
      coupon: coupon,
      discount: parseFloat(discount),
      customerName: order.name,
      cartId: randomID.toString(),
      paidValue: parseFloat(paidValue),
      addShipping: isShipping,
    };

    try {
      setLoading(true);
      setCouponType('');
      const response = await createOrder(object);
      if (response.status !== 'success') {
        toast.error(response.msg);
      } else {
        toast.success(response.msg);
        setOpenChooseCustomer(false);
        newCart();
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }

    console.log(object);
  };

  const handleSubmitOrderWithoutCustomer = async () => {
    const object = {
      userId: null,
      city: '',
      address: '',
      mobile: '',
      coupon: coupon,
      discount: parseFloat(discount),
      customerName: '',
      cartId: randomID.toString(),
    };

    try {
      setLoading(true);
      setCouponType('');
      const response = await createOrder(object);
      if (response.status !== 'success') {
        toast.error(response.msg);
        setOpenSubmitConfirmation(false);
      } else {
        setOpenSubmitConfirmation(false);
        newCart();
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };
  const handleAddToCartByBarcode = async () => {
    try {
      setLoading(true);
      const response = await addToCartByBarcode({
        cartId: randomID,
        barcode: barCode,
        priceType,
      });

      fetchCart(localStorage.getItem('randomID'));

      if (response.status == 'error') {
        toast.error(response.msg, 'top-left');
      }
      console.log(response);
      setBarCode('');
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddToCartByBarcode();
    }
  };

  const fetchCart = async (id) => {
    let sub_total = 0;
    try {
      setLoading(true);

      const response = await getCart(id);
      {
      }
      if (response.status == 'success') setCart([...response.data]);

      setLoading(false);

      {
        console.log(response.data);
        response.data.map(
          (item, index) => (sub_total = sub_total + item.total)
        );
      }
      setShippingPrice(0);

      setSubTotal(sub_total);
    } catch (error) {
      throw error;
    }
  };

  const handleCheckCoupon = async (code) => {
    try {
      setLoading(true);
      const response = await checkCoupon(coupon);
      console.log(response.data);

      if (response.data.length == 0) {
        toast.error('الكوبون غير صالح');
        setDiscountCoupon(0);
      } else if (response.data[0].coupontype == 'ثابت') {
        setCouponType('ثابت');
        setDiscountCoupon(response.data[0].couponvalue);
        // setCouponvalue(response.data[0].couponvalue);
      } else if (response.data[0].coupontype == 'نسبة مئوية') {
        setCouponType('نسبة مئوية');
        setDiscountCoupon((subTotal * response.data[0].couponvalue) / 100);
        setCouponvaluePercentage(response.data[0].couponvalue);
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleCreateNewCart = () => {
    newCart();
  };

  const handleShipping = () => {
    setIsShipping(!isShipping);
  };

  const fetchPriceCity = async (name) => {
    try {
      setLoading(true);
      const response = await getCityPrice(name);
      if (response.status == 'success') {
        setShippingPrice(response.price);
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // Function to format date to yyyy/mm/dd
  function formatDate(date) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 since getMonth() returns 0-11
    let day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  const handlePrint = (orderData) => {
    let tableString = orderData.cart.map((item, index) => {
      return `
        <tr>
          <td>${index + 1}</td>
          <td>${item.productItemNumber}</td>
          <td>${item.name}</td>
          <td>${item.quantity}</td>
          <td>${item.price}</td>
          <td>${item.total}</td>
        </tr>`;
    });
    tableString = tableString.join('\n');
    console.log('this is a string table : \n', tableString);
    const printWindow = window.open('', '', 'width=1000,height=1200');
    printWindow.document.write(`
       
    <!DOCTYPE html>
    <html lang="ar">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=0.5">
        <title>فاتورة</title>
        <style>


        *{
          font-family: "Times New Roman", Times, serif;
        }
            body {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                direction: rtl;
                text-align: right;
                margin: 40px;
                background-color: #f9f9f9;
                color: #333;
            }
            .invoice-container {
                max-width: 700px;
                margin: auto;
                background-color: #fff;
                padding: 20px;
                border-radius: 8px;
            }
            .invoice-header, .invoice-footer {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width:80%;
                margin-left :auto;
                margin-right:auto;
            }
            .invoice-header div, .invoice-footer div {
                margin-bottom: 10px;
                width: 48%;
            }
            .invoice-title {
                text-align: center;
                margin-bottom: 20px;
            }
            .invoice-title h1 {
                margin: 0;
                font-size: 1em;
            }
            .invoice-title p {
                margin: 5px 0;
                font-size: 0.8em;
            }
            table {
                width: 80%;
                border-collapse: collapse;
                margin-bottom: 20px;
                margin-left :auto;
                margin-right:auto;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 3px;
            }
            th {
                background-color: #f2f2f2;
                text-align: center;
            }
            td {
                text-align: center;
            }
            .total {
                background-color: #f2f2f2;
                font-weight: bold;
            }
            .invoice-footer div {
                font-size: 0.8em;
               
            }
        </style>
    </head>
    <body>
    
        <div class="invoice-container">
            <div class="invoice-title">
                <h1>فاتورة</h1>
                <p><strong>الحمارشة للأدوات المنزلية </strong></p>
                <p>تاريخ الطباعة: ${getCurrentDate()}</p>
            </div>
            
            <div class="invoice-header">
                <div><strong>الاسم: </strong>${orderData.name}</div>
                <div><strong>الهاتف: </strong>${orderData.mobile}</div>
                <div><strong>المدينة: </strong>${orderData.city}</div>
                <div><strong>العنوان: </strong> ${orderData.address}</div>
            </div>
    
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>رقم المنتج</th>
                        <th>الاسم</th>
                        <th>الكمية</th>
                        <th>السعر</th>
                        <th>المجموع</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Rows of products will go here -->
                  
                      ${tableString}
                        
                    <!-- Repeat above <tr> block for each product -->
                </tbody>
            </table>
    
            <div class="invoice-footer">
                <div><strong>المجموع:</strong> ${orderData.subTotal}</div>
                <div><strong>الكوبون:</strong> ${orderData.coupon}</div>
                <div><strong>خصم الكوبون: </strong>${
                  orderData.discountCoupon
                }</div>
                <div><strong>الخصم:</strong> ${orderData.discount}</div>
                <div><strong>سعر التوصيل:</strong> ${
                  orderData.shippingPrice
                }</div>
                <div class="total"><strong>المجموع الكلي:</strong> ${
                  orderData.total
                }</div>
                <div><strong>المبلغ المدفوع:</strong> ${
                  orderData.paidValue
                }</div>
                <div><strong>المتبقي:</strong> ${
                  orderData.total - orderData.paidValue
                }</div>
            </div>
        </div>
    
    </body>
    </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handlePrintWithoutCustomer = (orderData) => {
    let tableString = orderData.cart.map((item, index) => {
      return `
        <tr>
          <td>${index + 1}</td>
          <td>${item.productItemNumber}</td>
          <td>${item.name}</td>
          <td>${item.quantity}</td>
          <td>${item.price}</td>
          <td>${item.total}</td>
        </tr>`;
    });
    tableString = tableString.join('\n');
    console.log('this is a string table : \n', tableString);
    const printWindow = window.open('', '', 'width=1000,height=1200');
    printWindow.document.write(`
       
    <!DOCTYPE html>
    <html lang="ar">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=0.5">
        <title>فاتورة</title>
        <style>

        *{
          font-family: "Times New Roman", Times, serif;
        }
            body {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                direction: rtl;
                text-align: right;
                margin: 40px;
                background-color: #f9f9f9;
                color: #333;
            }
            .invoice-container {
                max-width: 700px;
                margin: auto;
                background-color: #fff;
                padding: 20px;
                border-radius: 8px;
            }
            .invoice-header, .invoice-footer {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width:80%;
                margin-left :auto;
                margin-right:auto;
            }
            .invoice-header div, .invoice-footer div {
                margin-bottom: 10px;
                width: 48%;
            }
            .invoice-title {
                text-align: center;
                margin-bottom: 20px;
            }
            .invoice-title h1 {
                margin: 0;
                font-size: 1em;
            }
            .invoice-title p {
                margin: 5px 0;
                font-size: 0.8em;
            }
            table {
                width: 80%;
                border-collapse: collapse;
                margin-bottom: 20px;
                margin-left :auto;
                margin-right:auto;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 3px;
            }
            th {
                background-color: #f2f2f2;
                text-align: center;
            }
            td {
                text-align: center;
            }
            .total {
                background-color: #f2f2f2;
                font-weight: bold;
            }
            .invoice-footer div {
                font-size: 0.8em;
               
            }
        </style>
    </head>
    <body>
    
        <div class="invoice-container">
            <div class="invoice-title">
                <h1>فاتورة</h1>
                <p><strong>الحمارشة للأدوات المنزلية </strong></p>
                <p>تاريخ الطباعة: ${getCurrentDate()}</p>
            </div>
            
             <div class="invoice-header">
                
            </div>
    
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>رقم المنتج</th>
                        <th>الاسم</th>
                        <th>الكمية</th>
                        <th>السعر</th>
                        <th>المجموع</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Rows of products will go here -->
                  
                      ${tableString}
                        
                    <!-- Repeat above <tr> block for each product -->
                </tbody>
            </table>
    
            <div class="invoice-footer">
                <div><strong>المجموع:</strong> ${orderData.subTotal}</div>
                <div><strong>الكوبون:</strong> ${orderData.coupon}</div>
                <div><strong>خصم الكوبون: </strong>${
                  orderData.discountCoupon
                }</div>
                <div><strong>الخصم:</strong> ${orderData.discount}</div>
               
                <div class="total"><strong>المجموع الكلي:</strong> ${
                  orderData.total
                }</div>
            </div>
        </div>
    
    </body>
    </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleChoosePriceType = (event, newPriceType) => {
    if (newPriceType !== null) {
      setPriceType(newPriceType);
      console.log('hellow');
    }
  };

  useEffect(() => {
    if (isShipping) {
      fetchPriceCity(order.city);
    } else {
      setShippingPrice(0);
    }
  }, [selectedCustomer, isShipping]);

  useEffect(() => {
    setTotal(subTotal - discount - discountCoupon + shippingPrice);
  }, [subTotal, discount, discountCoupon, shippingPrice]);

  useEffect(() => {
    fetchCart(selectedCartById);
    localStorage.setItem('randomID', selectedCartById);
    setRandomID(selectedCartById);
    setBarCode('');
    setSubTotal(0);
    setCoupon('');
    setDiscount(0);
    setDiscountCoupon(0);
    setTotal(0);
    setShippingPrice(0);
    setCouponType('');
  }, [selectedCartById]);

  useEffect(() => {
    let cartIds = [];
    const fetchPindingCarts = async () => {
      setLoading(true);
      const response = await getPendingCarts();
      cartIds = response.data.map((item) => item.cartId);
      setPindingCarts(cartIds);
      setLoading(false);
      console.log('the pinding carts are : ', cartIds);
    };

    generateRandomNumber();
    fetchPindingCarts();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Box>
        <Box
          sx={{
            display: 'flex',
            // flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'right',
            // alignItems: 'center',
            // border: '1px solid #000',
            margin: '10px',
            borderRadius: 1,
            padding: '5px',
          }}
        >
          <Box
            sx={{
              color: '#00000099',
              minWidth: '350px',
              margin: '20px',
              marginRight: '10px',
              justifyContent: isMobile ? 'center' : 'right',
            }}
          >
            <Box sx={{}}>
              <Typography> رقم سلة الشراء :{randomID}</Typography>
            </Box>

            <Box
              sx={{
                display: 'felx',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '50px',
                height: '60px',
              }}
            >
              {' '}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  size='small'
                  sx={{
                    width: '50%',
                    maxWidth: 400,
                    margin: '5px',
                  }}
                  value={selectedCartById}
                  onChange={(e, newValue) => {
                    if (newValue !== null) setSelectedCartById(newValue);
                  }}
                  onInputChange={(e, value) => {
                    // setSelectedCartById(value);
                  }}
                  options={pindingCarts}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ float: 'right' }} />
                  )}
                  freeSolo // Enable the ability to enter custom text
                />

                <Box sx={{}}>
                  <Button sx={{ backgroundColor: '' }}>
                    <AddIcon
                      sx={{ width: '40px', height: '40px' }}
                      onClick={handleCreateNewCart}
                    />
                  </Button>
                </Box>
              </Box>
              {}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '5px',
              marginLeft: '20px',
              minWidth: '350px',
            }}
          >
            <Box sx={{}}>
              {/* <FormControl sx={{ alignItems: 'center' }}>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  نوع السعر المعتمد
                </FormLabel>


                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={priceType}
                  onChange={(e) => setPriceType(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value='0'
                    control={<Radio />}
                    label='تجزئة'
                  />
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    label='جملة 1'
                  />

                  <FormControlLabel
                    value='2'
                    control={<Radio />}
                    label='جملة 2'
                  />
                </RadioGroup>

              </FormControl> */}{' '}
              <Typography
                sx={{
                  marginBottom: '10px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                نوع السعر المعتمد
              </Typography>
              <ToggleButtonGroup
                value={priceType}
                exclusive
                onChange={handleChoosePriceType}
                aria-label='نوع السعر المعتمد'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  '& .MuiToggleButtonGroup-grouped': {
                    border: 0,
                    borderRadius: '8px',
                    margin: '0 5px',
                    '&:first-of-type': {
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    },
                    '&:last-of-type': {
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    },
                  },
                }}
              >
                <ToggleButton
                  value='0'
                  aria-label='تجزئة'
                  sx={{
                    padding: '10px 20px',
                    color: '#555',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <Typography>تجزئة</Typography>
                </ToggleButton>
                <ToggleButton
                  value='1'
                  aria-label='جملة 1'
                  sx={{
                    padding: '10px 20px',
                    color: '#555',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <Typography>جملة 1</Typography>
                </ToggleButton>
                <ToggleButton
                  value='2'
                  aria-label='جملة 2'
                  sx={{
                    padding: '10px 20px',
                    color: '#555',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <Typography>جملة 2</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          <Box
            sx={{
              margin: '10px',
              float: 'left',
              // border: '1px solid #000',
            }}
          >
            <Input
              type='text'
              value={barCode}
              onKeyDown={handleKeyDown}
              onChange={(event) => setBarCode(event.target.value)}
              placeholder=' الباركود أو رقم المنتج'
              style={{ margin: '10px' }}
            />

            <Button
              sx={{
                borderRadius: '50%',
                minWidth: 40,
                minHeight: 40,
                height: 40,
                padding: 0,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              variant='contained'
              onClick={handleAddToCartByBarcode}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>

        <Box>
          <CartTable cart={cart} fetchCart={fetchCart} />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <Box
          sx={{
            display: 'grid',
            // gap: '10px',
            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: 1,
            padding: '20px',
            margin: 5,
            marginTop: 0,
            marginBottom: 0,
            width: '70%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 1,
              margin: 1,
              border: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Typography
              sx={{
                marginLeft: '100px',
                minWidth: 130,
              }}
            >
              المجموع
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                minWidth: 50,
                fontWeight: 'bold',
                fontSize: 20,
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: 1,
              }}
            >
              {subTotal}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 1,
              margin: 1,
              border: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Typography sx={{}}>كوبون</Typography>
            <Input
              type='text'
              value={coupon}
              onChange={(event) => setCoupon(event.target.value)}
              placeholder='   '
              style={{ margin: '10px', textAlign: 'center' }}
            />
            <Button onClick={() => handleCheckCoupon(coupon)}>فحص</Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 1,
              margin: 1,
              border: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Typography
              sx={{
                marginLeft: '100px',
                minWidth: 130,
              }}
            >
              قيمة خصم الكوبون
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                minWidth: 50,
                fontWeight: 'bold',
                fontSize: 20,
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: 1,
              }}
            >
              {discountCoupon}{' '}
              {couponType != 'ثابت' && couponType != ''
                ? `(%${couponvaluePercentage})`
                : ''}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 1,
              margin: 1,
              border: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Typography sx={{}}>الخصم</Typography>
            <Input
              type='number'
              value={discount}
              onChange={(event) => setDiscount(event.target.value)}
              placeholder='الخصم'
              sx={{
                margin: '10px',
                paddingLeft: '1px',
                textAlign: 'center',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 1,
              margin: 1,
              border: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Typography
              sx={{
                marginLeft: '100px',
                backgroundColor: 'yellow',
                borderRadius: '5px',
                padding: '1px',
                minWidth: 130,
              }}
            >
              المجموع الكلي
            </Typography>

            <Typography
              sx={{
                textAlign: 'center',
                minWidth: 50,
                fontWeight: 'bold',
                fontSize: 20,
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: 1,
              }}
            >
              {total}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 5,
          }}
        >
          <Button
            disabled={cart.length == 0}
            variant='contained'
            sx={{
              minWidth: '170px',
              margin: '5px',
              backgroundColor: '#fcc318',
              color: 'black',
              '&:hover': {
                backgroundColor: '#ffa000', // Change color on hover
              },
            }}
            onClick={() => {
              setPaidValue(total);
              setOpenChooseCustomer(true);
            }}
          >
            {' '}
            تثبيت طلبية مع زبون
          </Button>
          <Button
            disabled={cart.length == 0}
            variant='contained'
            color='primary'
            sx={{ minWidth: '170px', margin: '5px' }}
            onClick={() => setOpenSubmitConfirmation(true)}
          >
            تثبيت طلبية بدون زبون
          </Button>

          <Button
            disabled={cart.length == 0}
            onClick={() => {
              const orderData = {
                subTotal: subTotal,
                coupon: coupon,
                discountCoupon: discountCoupon,
                discount: discount,
                total: total,
                cart: cart,
              };

              handlePrintWithoutCustomer(orderData);
            }}
          >
            طباعة
          </Button>
        </Box>
      </Box>

      <Box>
        <Products
          showAddToCart={true}
          handleAddToCartById={handleAddToCartById}
        />
      </Box>

      {/*Confirm dialog Component*/}
      <Dialog
        sx={{ textAlign: 'right' }}
        open={openSubmitConfirmation}
        onClose={() => setOpenSubmitConfirmation(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'تثبيت طلبية'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            هل أنت متأكد من عملية التثبيت ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSubmitConfirmation(false);
            }}
            color='primary'
          >
            الغاء
          </Button>
          <Button
            onClick={handleSubmitOrderWithoutCustomer}
            color='primary'
            autoFocus
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>

      {/*Confirm dialog Component  to add order*/}
      <Dialog
        open={openChooseCustomer}
        onClose={() => setOpenChooseCustomer(false)}
        PaperProps={{
          sx: {
            maxWidth: '1500px',
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogTitle
          sx={{
            position: 'relative',
            width: '100%',
            height: '50px',
          }}
        >
          <Button
            onClick={() => setOpenChooseCustomer(false)}
            sx={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              width: '30px',
              height: '30px',
              borderRadius: '100%',
            }}
          >
            <CancelIcon />
          </Button>
        </DialogTitle>

        <DialogContent sx={{ maxWidth: '900px' }}>
          <Customers
            showSelectCustomer={true}
            setOrder={setOrder}
            setSelectedCustomer={setSelectedCustomer}
          />

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              border: '1px solid rgba(224, 224, 224, 1)',
              padding: '10px',
              direction: 'rtl',
              // width: 'calc(100% - 160px)',
              marginLeft: '10px',
              marginRight: '30px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                الاسم
              </Typography>
              <Typography>{order.name}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                الهاتف
              </Typography>
              <Typography>{order.mobile}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                المدينة
              </Typography>
              <Typography>{order.city}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                العنوان
              </Typography>
              <Typography>{order.address}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                المجموع
              </Typography>
              <Typography>{parseFloat(subTotal)}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                كوبون
              </Typography>
              <Typography>{coupon}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                الخصم
              </Typography>
              <Typography>{parseFloat(discount)}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                خصم الكوبون
              </Typography>
              <Typography>{parseFloat(discountCoupon)}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '200px',

                  minWidth: '140px',
                }}
              >
                سعر التوصيل{' '}
              </Typography>
              {isShipping && (
                <Typography
                  sx={{
                    marginLeft: '10px',
                    fontWeight: 'bold',
                    minWidth: '140px',
                  }}
                >
                  {parseFloat(shippingPrice)}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                }}
              >
                احتساب الشحن
              </Typography>
              <Checkbox
                Checked={isShipping}
                onChange={handleShipping}
                {...label}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                  backgroundColor: 'yellow',
                }}
              >
                المجموع الكلي
              </Typography>
              <Typography>{parseFloat(total)}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions PaperProps={{ maxWidth: '1400px' }}>
          <Box
            sx={{
              direction: 'rtl',
              display: 'flex',
              flexDirection: 'row',
              width: '800px',

              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                direction: 'rtl',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  marginLeft: '10px',
                  fontWeight: 'bold',
                  minWidth: '140px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                المبلغ المدفوع
              </Typography>
              <Input
                value={paidValue}
                onChange={(e) => setPaidValue(e.target.value)}
                type='number'
                placeholder='المبلغ المدفوع'
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', margin: '5px' }}>
              <Button
                disabled={order.id ? false : true}
                onClick={() => handleSubmitOrderWithCustomer()}
              >
                تثبيت الطلبية
              </Button>

              <Button
                disabled={order.id ? false : true}
                onClick={() => {
                  console.log(cart);
                  const order_info = {
                    name: order.name,
                    mobile: order.mobile,
                    city: order.city,
                    address: order.address,
                    paidValue: paidValue,
                    coupon: coupon,
                    discountCoupon: parseFloat(discountCoupon),
                    subTotal: parseFloat(subTotal),
                    shippingPrice: parseFloat(shippingPrice),
                    discount: parseFloat(discount),
                    total: parseFloat(total),
                    cart: cart,
                  };
                  console.log(order_info);
                  handlePrint(order_info);
                }}
              >
                طباعة
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddOrders;
