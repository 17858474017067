import React, { useState, useEffect } from 'react';

import { getSuppliers, editSupplier, getSuppliersPayments } from '../APIS';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import SupplierPaymentsDialog from './SupplierPaymentsDialog';

import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import AddSupplierDialog from './AddSupplierDialog';

import Loading from './Loading';

const useFakeMutation = () => {
  return React.useCallback(
    (supplier) =>
      new Promise((resolve, reject) => {
        resolve({ ...supplier });
      }),
    []
  );
};

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openPaymentsDialog, setOpenPaymentsDialog] = useState(false);
  const [selectedIdSupplier, setSelectedIdSupplier] = useState(0);

  const [loading, setLoading] = useState(true);

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'supplier_name',
      headerName: 'الاسم',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'text',
    },

    {
      field: 'supplier_mobile',
      headerName: 'الهاتف',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      type: 'text',
      editable: true,
    },

    {
      field: 'supplier_company',
      headerName: 'الشركة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'text',
    },
    {
      field: 'supplier_country',
      headerName: 'الدولة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'text',
    },
    {
      field: 'supplier_email',
      headerName: 'الايميل',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 170,
      editable: true,
      type: 'text',
    },
    {
      field: 'total_order_amount',
      headerName: 'مجموع المشتريات',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 200,
      editable: false,
      type: 'number',
    },
    {
      field: 'total_payment_amount',
      headerName: 'مجموع الدفعات',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: false,
      type: 'number',
    },
    {
      field: 'total_remaining_amount',
      headerName: 'المتبقي',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<AttachMoneyIcon sx={{ color: 'black' }} />}
            label='payments'
            onClick={() => {
              handleShowPaymentsClick(id);
              setSelectedIdSupplier(id);
            }}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const data = {
        name: newRow.supplier_name,
        mobile: newRow.supplier_mobile,
        company: newRow.supplier_company,
        country: newRow.supplier_country,
        email: newRow.supplier_email,
        id: newRow.id,
      };

      console.log(data);

      const result = await editSupplier(data);
      if (result.status !== 'success') {
        toast.error('لم تتم عملية التعديل بنجاح حاوب مرة اخرى', 'top-left');
      } else {
        toast.success('تم التعديل بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleShowPaymentsClick = async (id) => {
    try {
      setLoading(true);
      const response = await getSuppliersPayments(id);
      setOpenPaymentsDialog(true);
      // console.log('the data is : ', response);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        setLoading(true);
        const response = await getSuppliers();
        console.log(response.data);
        setSuppliers(response.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchSuppliers();
  }, []);
  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Button onClick={() => setOpenAddDialog(true)}>
          <AddIcon />
        </Button>
        <Box sx={{ width: '98%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              //   setSelectedRow(row.row);
            }}
            sx={{
              height: '700px',
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={suppliers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            // disableColumnSorting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>

      {/*Dialog for adding Supplier*/}
      {openAddDialog && (
        <AddSupplierDialog
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          setSuppliers={setSuppliers}
        />
      )}

      {/*Dialog for adding Supplier*/}
      {openPaymentsDialog && (
        <SupplierPaymentsDialog
          id={selectedIdSupplier}
          openPaymentsDialog={openPaymentsDialog}
          setOpenPaymentsDialog={setOpenPaymentsDialog}
        />
      )}
    </>
  );
};

export default Suppliers;
