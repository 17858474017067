import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, Button } from '@mui/material';
import SideBar from './SideBar';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';

const Structure = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [showSidebar, setShowSidebar] = useState(!isMobile); // Initially show sidebar if not mobile

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/';
    }
  }, [window.location.href]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        minHeight: '100vh',
      }}
    >
      {showSidebar ? (
        <Box
          sx={{
            minWidth: isMobile ? '100vw' : '200px',
            position: 'sticky',
            top: 0,
            border: '1px solid #ccc',
          }}
        >
          <SideBar toggleSidebar={toggleSidebar} />
        </Box>
      ) : (
        <></>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          width: isMobile ? '100%' : '85%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#fcc318',
            alignItems: 'center',
            marginTop: { xs: 0, md: '0px' },
            textAlign: 'center',
            position: 'sticky',
            top: 0,
            zIndex: 999,
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
          }}
        >
          {!showSidebar ? (
            <Box
              sx={{
                position: 'sticky',
                top: 0,
              }}
            >
              <Button
                onClick={toggleSidebar}
                sx={{ color: 'gray', marginTop: 'auto' }}
              >
                <ReorderOutlinedIcon />
              </Button>
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ marginRight: '30px' }}>
            <h2>حمارشة للأدوات المنزلية</h2>
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default Structure;
