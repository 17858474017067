import React, { useEffect, useState } from 'react';
import ShowImageDialog from '../components/ShowImagesDialog';

const TimerComponent = () => {
  const [seconds, setSeconds] = useState(0);

  return (
    <div>
      <ShowImageDialog />
    </div>
  );
};

export default TimerComponent;
