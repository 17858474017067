import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from '@mui/material';

const theme = createTheme({
  direction: 'rtl',
});

const RootComponent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
        <ToastContainer position={isMobile ? 'top-center' : 'top-left'} />
      </ThemeProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
