import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Dialog,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  getProductImages,
  uploadAndGetLink,
  addProductImage,
  deleteProductImage,
} from '../APIS';
import { toast } from 'react-toastify';
import { Typography } from 'antd';

const ShowImagesDialog = (props) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDeleteImageClick = async ({ id }) => {
    console.log('this is th id : ', id);
    try {
      setLoading(true);
      const response = await deleteProductImage(id);
      setLoading(false);
      if (response.status != 'success') {
        toast.error('لم تتم عملية الحذف بنجاح');
      } else {
        toast.success('تمت عملية الحذف بنجاح');
        const updatedImages = await getProductImages(props.selectedProductId);
        setImages(updatedImages.data);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const response = await getProductImages(props.selectedProductId);
        setImages(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchImages();
  }, [props.selectedProductId]);

  const onDrop = async (acceptedFiles) => {
    setLoading(true);
    try {
      const uploadPromises = acceptedFiles.map(async (file) => {
        // Upload the file and get the link
        const uploadResult = await uploadAndGetLink(file);

        // Construct the attachment body
        const body = {
          productId: props.selectedProductId, // Assuming `selectedProductId` is the order ID
          url: uploadResult.url, // Assuming the uploadResult contains the URL
        };

        console.log('The body:', body);

        const response = await addProductImage(body);

        console.log('The response is:', response);

        return {
          ...file,
          url: uploadResult.url,
        };
      });

      const uploadedImages = await Promise.all(uploadPromises);
      const response = await getProductImages(props.selectedProductId);
      setImages(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error uploading images:', error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  // const {
  //   getRootProps,
  //   getInputProps,
  //   isFocused,
  //   isDragAccept,
  //   isDragActive,
  //   isDragReject,
  // } = useDropzone({ onDrop, accept: { 'image/*': [] }, maxFiles: 1 });

  return (
    <Dialog
      sx={{ direction: 'rtl' }}
      open={props.openShowImagesDialog}
      onClose={() => props.setOpenShowImagesDialog(false)}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>صور</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed grey',
                borderRadius: '8px',
                padding: '16px',
                textAlign: 'center',
                cursor: 'pointer',
                marginBottom: '16px',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>افلت الملفات هنا...</p>
              ) : (
                <p>اسحب وأسقط الصور هنا، أو انقر لتحديد الصور</p>
              )}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {images.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Box
                      style={{
                        position: 'relative',
                        width: 'fit-content',
                        display: 'inline-block',
                      }}
                    >
                      <Button
                        sx={{
                          color: 'red',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          padding: 0,
                          minWidth: 'auto',
                        }}
                        onClick={() => {
                          handleDeleteImageClick({ id: image.id });
                        }}
                      >
                        <HighlightOffIcon />
                      </Button>
                      <img
                        src={image.preview || image.url}
                        alt={image.description || `Image ${index + 1}`}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpenShowImagesDialog(false)}>
          الغاء
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowImagesDialog;
