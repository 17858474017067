import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import AddProductDialog from './AddProductDialog';
import DropZone from './DropZone';
import EditIcon from '@mui/icons-material/Edit';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ImageIcon from '@mui/icons-material/Image';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import ShowImagesDialog from './ShowImagesDialog';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useMediaQuery } from '@mui/material';

import {
  getProducts,
  editProduct,
  deleteProduct,
  getCategories,
} from '../APIS';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

const useFakeMutation = () => {
  return React.useCallback(
    (product) =>
      new Promise((resolve, reject) => {
        if (product.itemno?.trim() === '') {
          reject(new Error('Error while saving user: itemno cannot be empty.'));
        } else {
          resolve({ ...product, total: product.cost * product.quantity });
        }
      }),
    []
  );
};

export default function ProductsPage(props) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openShowImagesDialog, setOpenShowImagesDialog] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [callDropZone, setCallDropZone] = useState(false);
  const [total, setTotal] = useState(0);
  const token = localStorage.getItem('token');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [image, setImage] = useState('');

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'itemno',
      headerName: 'رقم المنتج',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: true,
      type: 'text',
    },
    {
      field: 'image',
      headerName: 'الصورة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      renderCell: (params) => (
        <Box sx={{ padding: 1 }}>
          <img
            src={params.value}
            alt='item'
            style={{
              width: '100%',
              aspectRatio: '1/1',
              objectFit: 'contain',
              cursor: 'pointer',
            }}
            onClick={() => handleImageClick(params.value)}
            // onDoubleClick={() => handleEditImage(params.value)}
          />
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'الاسم',
      align: 'center',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      renderCell: (params) => (
        <div
          style={{
            // whiteSpace: 'normal',
            // lineHeight: '1.5',
            textAlign: 'right',
          }}
        >
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: 'categoryName',
      headerName: 'التصنيف',
      align: 'center',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: categories,
      align: 'center',
      editable: true,
    },
    {
      field: 'quantity',
      headerName: 'الكمية',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      field: 'unit',
      headerName: 'وحدة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'cost',
      headerName: 'التكلفة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      headerName: 'المجموع',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
      valueGetter: (value, row) => {
        return row.quantity * row.cost;
      },
    },
    {
      field: 'price1',
      headerName: 'سعر بيع التجزئة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: true,
      type: 'number',
    },
    {
      field: 'price2',
      headerName: 'سعر جملة 1',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: true,
      type: 'number',
    },
    {
      field: 'price3',
      headerName: 'سعر جملة 2',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: true,
      type: 'number',
    },
    {
      field: 'deletedprice',
      headerName: 'السعر المحذوف',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 120,
      editable: true,
      type: 'number',
    },
    {
      field: 'barcode',
      headerName: 'الباركود',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      field: 'description',
      headerName: 'الوصف',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 400,
      textAlign: 'right',
      direction: 'rtl',
      editable: true,
      type: 'text',
      renderCell: (params) => (
        <div
          style={{
            // whiteSpace: 'pre-wrap',
            // lineHeight: '1.5',
            // width: '200px',
            textAlign: 'right',
          }}
        >
          <p>{params.value}</p>
        </div>
      ),
    },

    {
      field: 'views',
      headerName: 'المشاهدات',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: 'red' }} />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,

          <>
            {' '}
            {props.showAddToCart && (
              <AddShoppingCartIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => props.handleAddToCartById(id)}
              />
            )}
          </>,

          <GridActionsCellItem
            icon={<ImageIcon sx={{ color: 'red' }} />}
            label='showImages'
            onClick={() => handleShowImages(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const [formData, setFormData] = useState({
    itemno: '',
    name: '',
    quantity: '',
    unit: '',
    cost: '',
    price1: '',
    price2: '',
    price3: '',
    barcode: '',
  });

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editProduct(newRow);

      if (result.status !== 'success') {
        toast.error('لم تتم عملية التعديل بنجاح حاوب مرة اخرى', 'top-left');
      } else {
        toast.success('تم التعديل بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleConfirmDelete = async ({}) => {
    setOpenDeleteConfirmation(false);
    setLoading(true);
    const response = await deleteProduct(selectedItemId);
    const productsData = await getProducts();

    if (response.status !== 'success') {
      toast.error('لم تتم عملية الحذف بنجاح', 'top-left');
    } else {
      toast.success('تمت عملية الحذف بنجاح ', 'top-left');
    }
    setProducts(productsData.data);
    setLoading(false);
  };

  const handleDeleteClick = (id) => () => {
    setSelectedItemId(id);
    setOpenDeleteConfirmation(true);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImageModal(true);
  };

  const handleShowImages = (id) => {
    setSelectedItemId(id);
    setOpenShowImagesDialog(true);
  };

  // const handleEditImage = () => {
  //   console.log('Editing image');
  // };

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      try {
        const productsData = await getProducts();
        setProducts(productsData.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        let c = [];
        categoriesData.data.forEach((category) => {
          c.push(category.name);
        });
        setCategories(c);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchProducts();
    fetchCategories();
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Button onClick={() => setOpenAddDialog(true)}>
          <AddIcon />
        </Button>
        <Box sx={{ width: '98%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              setSelectedRow(row.row);
            }}
            sx={{
              height: '700px',
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },

              // '& .MuiDataGrid-row': {
              //   minHeight: '50px', // Minimum height for each row
              // },
            }}
            // rowHeight={20}
            getRowHeight={() => 'auto'}
            rows={products}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            // disableColumnSorting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>

      {/*Dialog Component*/}
      <Dialog
        sx={{ textAlign: 'right' }}
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'حذف منتج'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            هل أنت متأكد من عملية الحذف ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirmation(false)}
            color='primary'
          >
            الغاء
          </Button>
          <Button onClick={handleConfirmDelete} color='primary' autoFocus>
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>

      {/*Dialog for Image*/}
      <Dialog
        open={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
          setCallDropZone(false);
        }}
      >
        <DialogContent
          sx={{
            padding: '10px',
            backgroundColor: '#fff',
          }}
        >
          {callDropZone ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h3 sx={{ textAlign: 'center' }}>لا يوجد صورة</h3>
              <DropZone
                setImage={setImage}
                onFinish={async (image) => {
                  let p = selectedRow;
                  p.image = image;
                  editProduct(p);
                  setOpenImageModal(false);
                }}
              />
            </Box>
          ) : (
            <>
              {selectedImage ? (
                <>
                  <EditIcon onClick={() => setCallDropZone(true)} />

                  <img
                    src={selectedImage}
                    alt='Product'
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: '600px',
                      minHeight: '600px',
                      objectFit: 'contain',
                    }}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <h3 sx={{ textAlign: 'center' }}>لا يوجد صورة</h3>
                  <DropZone
                    setImage={setImage}
                    onFinish={async (image) => {
                      let p = selectedRow;
                      p.image = image;
                      editProduct(p);
                      setOpenImageModal(false);
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>

      {/*Dialog for adding products*/}
      {openAddDialog && (
        <AddProductDialog
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          setProducts={setProducts}
          categories={categories}
          setCategories={setCategories}
        />
      )}

      {openShowImagesDialog && (
        <ShowImagesDialog
          openShowImagesDialog={openShowImagesDialog}
          setOpenShowImagesDialog={setOpenShowImagesDialog}
          selectedProductId={selectedItemId}
        />
      )}
    </>
  );
}
