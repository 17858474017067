// import React, { useState, useEffect } from 'react';

// import {
//   getOrderPayments,
//   deleteOrderPayment,
//   editOrderPayment,
//   addOrderPayment,
// } from '../APIS';
// import Loading from './Loading';

// import { Box, Button, Input, Typography } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import AddIcon from '@mui/icons-material/Add';

// import { useMediaQuery } from '@mui/material';

// import { toast } from 'react-toastify';

// import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
// // import { Typography } from 'antd';

// const useFakeMutation = () => {
//   return React.useCallback(
//     (orderPayment) =>
//       new Promise((resolve, reject) => {
//         resolve({ ...orderPayment });
//       }),
//     []
//   );
// };

// const PaymentsTable = (props) => {
//   const [loading, setLoading] = useState(false);
//   const [orderPayments, setOrderPayments] = useState([]);
//   const [selectedRow, setSelectedRow] = useState({});

//   const [amount, setAmount] = useState(0);
//   const [notes, setNotes] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   const isMobile = useMediaQuery('(max-width:600px)');

//   const mutateRow = useFakeMutation();

//   const columns = [
//     {
//       field: 'id',
//       headerName: '#',
//       headerAlign: 'center',
//       align: 'center',
//       flex: 1,
//       minWidth: 100,
//       editable: false,
//     },
//     {
//       field: 'amount',
//       headerName: 'المبلغ',
//       headerAlign: 'center',
//       align: 'center',
//       flex: 1,
//       minWidth: 100,
//       editable: false,
//       type: 'number',
//     },
//     {
//       field: 'dateadded',
//       headerName: 'تاريخ الاضافة',
//       align: 'center',
//       flex: 1,
//       minWidth: 100,
//       headerAlign: 'center',
//       type: 'date',
//       valueGetter: (value, row) => {
//         return new Date(row.dateadded);
//       },
//     },
//     {
//       field: 'notes',
//       headerName: 'الملاحظات',
//       headerAlign: 'center',
//       align: 'center',
//       flex: 1,
//       minWidth: 100,
//       editable: true,
//       type: 'text',
//     },
//     {
//       field: 'orderId',
//       headerName: 'رقم الطلبية',
//       headerAlign: 'center',
//       align: 'center',
//       flex: 1,
//       minWidth: 100,
//       type: 'text',
//     },

//     // {
//     //   field: 'actions',
//     //   type: 'actions',
//     //   headerName: 'Actions',
//     //   headerAlign: 'center',
//     //   align: 'center',
//     //   flex: 1,
//     //   minWidth: 100,
//     //   cellClassName: 'actions',
//     //   getActions: ({ id }) => {
//     //     return [
//     //       <GridActionsCellItem
//     //         icon={<DeleteIcon sx={{ color: 'red' }} />}
//     //         label='Delete'
//     //         onClick={() => handleDeletePayment(id)}
//     //         color='inherit'
//     //       />,
//     //     ];
//     //   },
//     // },
//   ];

//   const processRowUpdate = React.useCallback(
//     async (newRow) => {
//       const result = await editOrderPayment(newRow);

//       if (result.status !== 'success') {
//         toast.error('لم يتم تعديل الدفعة بنجاح', 'top-left');
//       } else if (result.status == 'success') {
//         toast.success('تم تعديل الدفعة بنجاح', 'top-left');
//         const response = await mutateRow(newRow);
//         const orderPayments = await getOrderPayments(props.orderNumber);
//         setOrderPayments(orderPayments.data);
//         return response;
//       }
//     },
//     [mutateRow]
//   );

//   const handleProcessRowUpdateError = React.useCallback((error) => {
//     // setSnackbar({ children: error.message, severity: 'error' });
//   }, []);

//   //   const handleDeletePayment = async (id) => {
//   //     try {
//   //       setLoading(true);
//   //       const response = await deleteOrderPayment(id);
//   //       const payments = await getOrderPayments(props.orderNumber);
//   //       if (response.status != 'success') {
//   //         toast.error('لم تتم عملية حذف الدفعة');
//   //       } else {
//   //         toast.success('تمت عملية الحذف بنجاح');
//   //       }
//   //       setOrderPayments(payments.data);
//   //       setLoading(false);
//   //     } catch (error) {
//   //       throw error;
//   //     }
//   //   };

//   const handleAddButton = async () => {
//     const object = {
//       amount: amount,
//       notes: notes,
//       orderId: props.orderNumber,
//     };

//     if (amount) {
//       setErrorMessage('');
//       try {
//         setLoading(true);
//         const response = await addOrderPayment(object);

//         if (response.status !== 'success') {
//           toast.error('لم يتم اضافة المبلغ');
//         } else {
//           toast.success('تمت اضافة المبلغ');
//           const orderPayments = await getOrderPayments(props.orderNumber);
//           setOrderPayments(orderPayments.data);
//           setAmount(0);
//           setNotes('');
//           setLoading(false);
//         }
//       } catch (error) {
//         throw error;
//       }
//     } else {
//       setErrorMessage(' رجاءً ، أدخل المبلغ');
//     }
//   };

//   useEffect(() => {
//     const fetchOrderPayments = async () => {
//       setLoading(true);
//       console.log(props.orderNumber);
//       const response = await getOrderPayments(props.orderNumber);
//       console.log('this is the response data : ', response.data);
//       setOrderPayments(response.data);
//       setLoading(false);
//     };

//     fetchOrderPayments();
//   }, []);

//   return (
//     <>
//       {loading && <Loading />}

//       <>
//         <Box
//           sx={{
//             display: 'flex',
//             direction: 'rtl',
//             border: '1px solid rgba(224, 224, 224, 1)',
//             margin: 1,
//           }}
//         >
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: isMobile ? 'column' : 'row',
//               border: '1px solid rgba(224, 224, 224, 1)',
//               borderRadius: 1,
//               padding: 1,
//               margin: 1,
//             }}
//           >
//             <Button
//               sx={{ width: '100%', height: '100%' }}
//               onClick={() => {
//                 handleAddButton();
//               }}
//             >
//               <AddIcon />
//             </Button>
//           </Box>
//           <Box
//             sx={{
//               border: '1px solid rgba(224, 224, 224, 1)',
//               borderRadius: 1,
//               padding: 1,
//               margin: 1,
//             }}
//           >
//             <Typography>المبلغ</Typography>
//             <Input
//               sx={{ margin: 1 }}
//               autoFocus
//               fullWidth
//               placeholder='المبلغ'
//               value={amount}
//               onChange={(e) => setAmount(e.target.value)}
//             />
//           </Box>

//           <Box
//             sx={{
//               border: '1px solid rgba(224, 224, 224, 1)',
//               borderRadius: 1,
//               padding: 1,
//               margin: 1,
//             }}
//           >
//             <Typography>الملاحظات</Typography>
//             <Input
//               sx={{ margin: 1 }}
//               multiline={true}
//               autoFocus
//               fullWidth
//               placeholder='الملاحظات'
//               value={notes}
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </Box>
//         </Box>
//         <Box sx={{ width: '98%', direction: 'rtl' }}>
//           <DataGrid
//             onRowClick={(row) => {
//               setSelectedRow(row.row);
//             }}
//             sx={{
//               height: 400,
//               padding: '5px',
//               '& .MuiDataGrid-cell': {
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               },
//             }}
//             getRowHeight={() => 'auto'}
//             rows={orderPayments}
//             columns={columns}
//             initialState={{
//               pagination: {
//                 paginationModel: { page: 0, pageSize: 10 },
//               },
//             }}
//             disableColumnFilter
//             disableColumnSelector
//             disableDensitySelector
//             disableExporting
//             disableRowSelectionOnClick
//             disableColumnMenu
//             pageSizeOptions={[10, 25, 50, 100]}
//             slots={{ toolbar: GridToolbar }}
//             slotProps={{
//               toolbar: {
//                 showQuickFilter: true,
//                 printOptions: {
//                   disableToolbarButton: true,
//                 },
//               },
//             }}
//             processRowUpdate={processRowUpdate}
//             onProcessRowUpdateError={handleProcessRowUpdateError}
//           />
//         </Box>

//         <Box>
//           <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
//         </Box>
//       </>
//     </>
//   );
// };

// export default PaymentsTable;

import React, { useState, useEffect } from 'react';

import {
  getOrderPayments,
  deleteOrderPayment,
  editOrderPayment,
  addOrderPayment,
} from '../APIS';
import Loading from './Loading';

import { Box, Button, Input, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

import { useMediaQuery } from '@mui/material';

import { toast } from 'react-toastify';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';

const useFakeMutation = () => {
  return React.useCallback(
    (orderPayment) =>
      new Promise((resolve, reject) => {
        resolve({ ...orderPayment });
      }),
    []
  );
};

const PaymentsTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [orderPayments, setOrderPayments] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isMobile = useMediaQuery('(max-width:600px)');

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'المبلغ',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
    },
    {
      field: 'dateadded',
      headerName: 'تاريخ الاضافة',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      type: 'date',
      valueGetter: (value, row) => {
        return new Date(row.dateadded);
      },
    },
    {
      field: 'notes',
      headerName: 'الملاحظات',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'text',
    },
    {
      field: 'orderId',
      headerName: 'رقم الطلبية',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'text',
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editOrderPayment(newRow);

      if (result.status !== 'success') {
        toast.error('لم يتم تعديل الدفعة بنجاح', 'top-left');
      } else if (result.status == 'success') {
        toast.success('تم تعديل الدفعة بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        const orderPayments = await getOrderPayments(props.orderNumber);
        setOrderPayments(orderPayments.data);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleAddButton = async () => {
    const object = {
      amount: amount,
      notes: notes,
      orderId: props.orderNumber,
    };

    if (amount) {
      setErrorMessage('');
      try {
        setLoading(true);
        const response = await addOrderPayment(object);

        if (response.status !== 'success') {
          toast.error('لم يتم اضافة المبلغ');
        } else {
          toast.success('تمت اضافة المبلغ');
          const orderPayments = await getOrderPayments(props.orderNumber);
          setOrderPayments(orderPayments.data);
          setAmount(0);
          setNotes('');
          setLoading(false);
        }
      } catch (error) {
        throw error;
      }
    } else {
      // setErrorMessage(' رجاءً ، أدخل المبلغ');
      toast.error('رجاءً ، أدخل المبلغ');
    }
  };

  useEffect(() => {
    const fetchOrderPayments = async () => {
      setLoading(true);
      console.log(props.orderNumber);
      const response = await getOrderPayments(props.orderNumber);
      console.log('this is the response data : ', response.data);
      setOrderPayments(response.data);
      setLoading(false);
    };

    fetchOrderPayments();
  }, []);

  return (
    <>
      {loading && <Loading />}

      <>
        <Box sx={{ direction: 'rtl', marginTop: '16px' }}>
          <DataGrid
            onRowClick={(row) => {
              setSelectedRow(row.row);
            }}
            sx={{
              height: 'auto',
              padding: '5px',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#f5f5f5',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={orderPayments}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[10, 25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            direction: 'rtl',
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '16px',
            flexDirection: 'column',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: 1,
              padding: 1,
              margin: 1,
              backgroundColor: '#f9f9f9',
            }}
          >
            <Box
              sx={{
                // border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: 1,
                padding: 1,
                margin: 1,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography>المبلغ</Typography>
              <Input
                sx={{ margin: 1 }}
                autoFocus
                placeholder='المبلغ'
                type='number'
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
              />
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: 1,
                padding: 1,
                margin: 1,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography>الملاحظات</Typography>
              <Input
                sx={{ margin: 1, width: '90%' }}
                multiline={true}
                autoFocus
                placeholder='الملاحظات'
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Box>
          </Box>

          <Box sx={{ margin: '10px' }}>
            <Button
              color='primary'
              onClick={() => {
                handleAddButton();
              }}
            >
              اضافة
            </Button>
          </Box>

          <Box>
            <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
          </Box>
        </Box>
      </>
    </>
  );
};

export default PaymentsTable;
